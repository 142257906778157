import React from "react";
import "./App.css";

function Template() {
  return (
    <div className="two-col">
      <div className="col-left">
        <div className="col-left-cover">
          {window.location.pathname && window.location.pathname.slice(1) ? (
            ""
          ) : (
            <h1>
              Please Subscribe to <br />{" "}
              <a
                href={`${process.env.REACT_APP_URL}/subscribe`}
                style={{ textDecoration: "none", color: "#bfa682" }}
              >
                LHI Circle{" "}
              </a>
            </h1>
          )}
        </div>
      </div>
      <div className="col-right">
        <div className="col-right-imgae">
          <img src="/banner-right.png" alt="banner" />
        </div>
      </div>
    </div>
  );
}

export default Template;
